.doctor-section {
    padding: 32px;
    margin: 32px auto;
    width: 80%;
    text-align: center;

    background-color: rgb(247, 247, 247);
    
}
.dt-title-content {
    margin: 0 0 64px 0;
}
.dt-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .7px;
}
.dt-title > span {
    position: relative;
}
.dt-title span::before {
    content: '';
    position: absolute;
    width: 64%;
    height: 4px;
    background-color: #9D2553;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}
.dt-description {
    margin: 64px auto 0;
    text-align: center;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    letter-spacing: .8px;
    line-height: 1.8rem;
}

.dt-smalldes{
    text-align: center;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
    letter-spacing: .8px;
  
}
/* Doctor Cards */
.dt-cards-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 32px;
}
.dt-card {
    width: 332px;
    height: 400px;
    gap: 32px;
    text-align: left;
    font-family: 'Rubik', sans-serif;
}
.dt-card-img {
    width: 100%;
    height: 275px;
    padding: 16px 32px 0;
    background-position: center;
    background-color: #DDE5F8;
    border-radius: 20px;
}
.dt-card-name {
    margin: 12px 0 0 0;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .7px;
}
.dt-card-title {
    margin: 6px 0 8px;
    color: #494949;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    letter-spacing: .7px;
}
.dt-card-stars {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .7px;
}
.dt-card-reviews {
    color: #5a5a5a;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .7px;
}
/* Responsive */
/* @media screen and (max-width: 700px) {
    .dt-title {
        font-size: 32px;
    }
    .dt-description {
        margin: 48px 0 0;
        font-size: 18px;
    }
} */

/* For screens less than 600px wide */
@media screen and (max-width: 600px) {
    .doctor-section {
        padding: 16px;
        margin: 16px auto;
        width: 90%;
    }

    .dt-title {
        font-size: 28px;
    }

    .dt-description {
        margin: 32px 0 0;
        font-size: 16px;
    }

    .dt-card {
        width: calc(100% - 32px); /* Adjusting card width to fit the screen */
        height: auto; /* Allowing cards to adjust their height */
        margin-bottom: 20px; /* Adding space between cards */
    }

    .dt-card-img {
        height: 200px; /* Reducing image height for smaller screens */
    }

    .dt-card-name {
        font-size: 18px;
        margin-top: 8px;
    }

    .dt-card-title {
        font-size: 16px;
        margin-bottom: 6px;
    }

    .dt-card-stars,
    .dt-card-reviews {
        font-size: 16px;
    }
    
    .dt-smalldes {
        font-size: 14px;
    }
}

